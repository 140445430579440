<div class="gradient-bg">

    <div class="p-3 d-none d-md-block">
        <a (click)="back()" class="bth-btn">
            <img src="/assets/img/webp/hand.webp" width="20" height="20" alt="" srcset="">
        </a>
    </div>

    <section class="thankyou-sec1">
        <div class="container thankyou-container">
            <a routerLink="/contact-us" class="bth-btn d-inline-block d-md-none">
                <img src="/assets/img/webp/hand.webp" width="25" height="25" alt="" srcset="">
            </a>
            <div class="row">
                <div class="col-md-12">
                    <div class="thankyou-div">
                        Thank You
                    </div>
                    <p class="thanks-p1">We look forward to collaborating with you !</p>
                    <p class="thanks-p2">Follow Us on Social Media:</p>
                    <ul class="thanks-socul">
                        <li>
                            <a href="https://www.facebook.com/proweb.ae/" target="_blank" class="facebook-link">
                                <img src="assets/img/webp/footer/facebook.webp" alt="" srcset=""></a>
                        </li>

                        <li>
                            <a href="https://www.linkedin.com/company/prowebdubai/?viewAsMember=true" target="_blank"
                                class="linkedin-link">  <img src="assets/img/webp/footer/linkedin.webp" alt="" srcset="">      </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/proweb.ae/" target="_blank" class="instagram-link">
                                <img src="assets/img/webp/footer/instagram.webp" alt="" srcset=""></a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/ProWebUnisys" target="_blank" class="youtube-link"><img src="assets/img/webp/youtube.webp" alt="" srcset=""></a>
                        </li>
                    </ul>
                    <p class="thanks-p3">If you require immediate attention then please contact us directly at <a
                            href="https://wa.me/9710527732125">+971-526549225</a></p>


                    <div class="d-flex justify-content-center my-2 my-md-4">
                        <div class="">
                            <div class="tpl-img-div">
                                <img src="https://rpatechnologies.in/assets/img/webp-image/home/bestdigitalmarketingnew.webp"
                                    alt="technology partner bigcommerce" />
                            </div>
                        </div>
                        <div class="">
                            <div class="tpl-img-div">
                                <img src="https://rpatechnologies.in/assets/img/webp-image/home/uaebusinessnew.webp"
                                    alt="technology partner google" />
                            </div>
                        </div>
                        <div class="">
                            <div class="tpl-img-div">
                                <img src="https://rpatechnologies.in/assets/img/webp-image/home/clutchnew.webp"
                                    alt="technology partner opencart" />
                            </div>
                        </div>
                        <div class="">
                            <div class="tpl-img-div">
                                <img src="https://rpatechnologies.in/assets/img/webp-image/home/top-web-design-company-in-dubainew.webp"
                                    alt="technology partner shopify" />
                            </div>
                        </div>
                        <div class="">
                            <div class="tpl-img-div">
                                <img src=" https://rpatechnologies.in/assets/img/webp-image/home/businessOfAppsnew.webp"
                                    alt="technology partner shopify" />
                            </div>
                        </div>
                    </div>

                    <a routerLink="/" class="bth-btn">
                        <img src="/assets/img/webp/hand.webp" width="25" height="25" alt="" srcset="">
                        &nbsp;<span>Back To
                            Home</span></a>

                </div>
            </div>
        </div>

        <div class="thumbimg-div">
            <img src="assets/img/thumbs-up.png" alt="thumbs up">
        </div>
    </section>

</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { FeaturesComponent } from './features/features.component';
import { FeaturesDetailsComponent } from './features-details/features-details.component';
import { ServicesComponent } from './services/services.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailsComponent } from './blog/blog-details/blog-details.component';
import { CategoryBlogsComponent } from './category-blogs/category-blogs.component';
import { PricingComponent } from './pricing/pricing.component';
import { FaqComponent } from './faq/faq.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DevelopersComponent } from './developers/developers.component';
import { CareersComponent } from './careers/careers.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServicesComponent } from './terms-of-services/terms-of-services.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { CareersDetailsComponent } from './careers/careers-details/careers.component';
import { SeoComponent } from './seo-packages/seo-packages.component';
import { SmmPackageComponent } from './smm-packages/smm-package.component';
import { WebMaintenancePackageComponent } from './web-maintenance-packages/web-maintenance-package.component';
import { MobileAppComponent } from './mobile-app-development-company-in-dubai/mobile-app.component';
import { EcommerceDevelopmentCompanyInDubaiComponent } from './ecommerce-development-company-in-dubai/ecommerce-development-company-in-dubai.component';
import { PpcPackageComponent } from './ppc-packages/ppc-package.component';
import { WebHostingPackageComponent } from './web-hosting-packages/web-hosting-package.component';
import { WebDesignComponent } from './web-design/web-design.component';
import { WebHostingServiceComponent } from './web-hosting-service/web-hosting-service.component';
import { CloudHostingComponent } from './cloud-hosting/cloud-hosting.component';
import { BlockchainServiceComponent } from './blockchain-service/blockchain-service.component';
import { SeoServiceComponent } from './seo-service/seo-service.component';
import { DedicatedTeamComponent } from './dedicated-team/dedicated-team.component';
import { MachineLearningServiceComponent } from './machine-learning-service/machine-learning-service.component';
import { FlutterComponent } from './flutter/flutter.component';
import { ArtificialIntelligenceComponent } from './artificial-intelligence/artificial-intelligence.component';
import { ReactNativeComponent } from './react-native/react-native.component';
import { NativeComponent } from './native/native.component';
import { QualityAssuranceComponent } from './quality-assurance/quality-assurance.component';
import { NodejsComponent } from './nodejs/nodejs.component';
import { VaptComponent } from './vapt/vapt.component';
import { PpcServiceComponent } from './ppc-service/ppc-service.component';
import { SmmServiceComponent } from './smm-service/smm-service.component';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./homepage/homepage.module').then((m) => m.HomepageModule),
	},
	{
		path: 'about-us',
		loadChildren: () => import('./about-page/about-page.module').then((m) => m.AboutPageModule),
	},
	{
		path: 'contact-us',
		loadChildren: () => import('./contact-page/contact-page.module').then((m) => m.ContactPageModule),
	},
	{
		path: 'case-study',
		loadChildren: () => import('./case-study-page/case-study-page.module').then((m) => m.CaseStudyPageModule),
	},
	{
		path: 'case-study/:id',
		loadChildren: () => import('./case-study-detail-page/case-study-detail-page.module').then((m) => m.CaseStudyDetailPageModule),
	},
	{
		path: 'portfolio',
		loadChildren: () => import('./portfolio-page/portfolio-page.module').then((m) => m.PortfolioPageModule),
	},
	// { 
	// 	path: 'hire-dedicated-developers/dubai', 
	// 	loadChildren: () => import('./hire-developer-page/hire-developer-page.module').then(m => m.HireDeveloperPageModule) 
	// },

	// { path: 'case-study', component: CaseStudiesComponent },
	// { path: 'case-study/:id', component: CaseStudyDetailComponent },
	// { path: 'about-us', component: AboutComponent },
	// { path: 'contact-us', component: ContactComponent },
	{ path: 'hire-dedicated-developers/dubai', component: DedicatedTeamComponent },
	
	{ path: 'web-design-company/dubai', component: WebDesignComponent },
	{ path: 'web-development-company/dubai', component: WebDevelopmentComponent },
	{
		path: 'ecommerce-development-company/dubai',
		component: EcommerceDevelopmentCompanyInDubaiComponent,
	},
	{
		path: 'mobile-app-development-company/dubai',
		component: MobileAppComponent,
	},
	{ path: 'web-hosting-company/dubai', component: WebHostingServiceComponent },
	{ path: 'cloud-hosting-company/dubai', component: CloudHostingComponent },
	{
		path: 'blockchain-development-company/dubai',
		component: BlockchainServiceComponent,
	},
	{ path: 'seo-company/dubai', component: SeoServiceComponent },
	
	
	{ path: 'careers', component: CareersComponent },
	{ path: 'careers-details/:slug', component: CareersDetailsComponent },
	{ path: 'blog', component: BlogComponent },
	{ path: 'blog/:id', component: BlogDetailsComponent },
	{ path: 'blog/category/:id', component: CategoryBlogsComponent },
	{ path: 'seo-packages', component: SeoComponent },
	{ path: 'ppc-packages', component: PpcPackageComponent },
	{ path: 'smm-packages', component: SmmPackageComponent },
	{
		path: 'website-maintenance-packages',
		component: WebMaintenancePackageComponent,
	},
	{ path: 'web-hosting-packages', component: WebHostingPackageComponent },
	{ path: 'privacy-policy', component: PrivacyPolicyComponent },
	{ path: 'terms-and-conditions', component: TermsOfServicesComponent },
	{ path: 'sitemap', component: SitemapComponent },
	{ path: 'thankyou', component: ThankyouComponent },
	{ path: 'developers', component: DevelopersComponent },

	{ path: 'features', component: FeaturesComponent },
	{ path: 'features-details', component: FeaturesDetailsComponent },
	{ path: 'services', component: ServicesComponent },
	
	{ path: 'pricing', component: PricingComponent },
	{ path: 'faq', component: FaqComponent },
	{ path: 'coming-soon', component: ComingSoonComponent },

	
	{ path: 'refund-policy', component: RefundPolicyComponent },
	{
		path: 'machine-learning/dubai',
		component: MachineLearningServiceComponent,
	},
	{ path: 'flutter-app-development/dubai', component: FlutterComponent },
	{ path: 'ai-development/dubai', component: ArtificialIntelligenceComponent },
	{
		path: 'react-native-app-development/dubai',
		component: ReactNativeComponent,
	},
	{ path: 'native-app-development/dubai', component: NativeComponent },
	{
		path: 'software-quality-assurance/dubai',
		component: QualityAssuranceComponent,
	},
	{ path: 'node-js-development/dubai', component: NodejsComponent },
	{ path: 'vapt-testing/dubai', component: VaptComponent },

	{ path: 'adwords-agency/dubai', component: PpcServiceComponent },
	{ path: 'social-media-marketing-company/dubai', component: SmmServiceComponent },
	

	{ path: '**', component: NotFoundComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})

export class AppRoutingModule { }
